import React, { useState } from 'react';
import axios from 'axios';


const AddressAutocomplete = ({ onAddressSelect, setIsLoading, setError, clearErrors, setAddressSelected }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const apikey = process.env.REACT_APP_SMARTYSTREETS_API_KEY;
  const smartyEndpoint = process.env.REACT_APP_CLEARESULT_SMARTY_ENDPOINT;
  const handleInputChange = async (event) => {
    const input = event.target.value;
    setQuery(input);

    if (input.length > 3) {  // Adjust the minimum length as per your preference
      try {
         // our own smarty endpoint https://us-autocomplete-pro.api.smartystreets.com/lookup
        const response = await axios.get(`${smartyEndpoint}/autocomplete?version=v2`, {
          params: {
            // key: apikey,  // Replace with your actual SmartyStreets API key
            search: input,
            max_results: 5,  // Number of suggestions to retrieve
          },
        });
        setSuggestions(response.data.suggestions);
        setAddressSelected(false);
        if (response.data.suggestions.length === 0) {
          setError('address', { type: 'manual', message: 'No results found' });
        } else {
          clearErrors('address');
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setError('address', { type: 'manual', message: 'Error fetching suggestions..Please reload and try again!' });
      }
    } else {
      setSuggestions([]);
      setAddressSelected(false);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setQuery(`${suggestion.street_line}, ${suggestion.city}, ${suggestion.state}`);
    setSuggestions([]);
    try {
        setIsLoading(true);
         // previous smarty endpoint https://us-street.api.smartystreets.com/street-address
        const response = await axios.get(`${smartyEndpoint}/street-address`, {
          params: {
            street: suggestion.street_line,
            city: suggestion.city,
            state: suggestion.state,
          },
        });
        const address = response.data[0];
        const { latitude, longitude } = address.metadata;
        onAddressSelect({ ...suggestion, latitude, longitude });
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching geocode:', error);
      }
  };

  return (
    <>
      <input
        type="text"
        id="address"
        value={query}
        onChange={handleInputChange}
        placeholder="Enter your address"
      />
      {suggestions.length > 0 && (
      <ul className="suggestions-list">
        {suggestions.map((suggestion, index) => (
          <li key={index} className="suggestion-item" onClick={() => handleSuggestionClick(suggestion)}>
            {suggestion.street_line}, {suggestion.city}, {suggestion.state}
          </li>
        ))}
      </ul>
      )}
    </>
  );
};

export default AddressAutocomplete;
