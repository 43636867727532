import React, { useState } from 'react';
import axios from 'axios';
import Smartcar from '@smartcar/auth';
import Connect from './components/Connect';
import './App.css';
import Loader from './components/Loader';
import UserForm from './components/UserForm';

const App = () => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
// this is prod endpoint
  const actualLambdaUrl = 'https://api-smartcar-prod.clearesult.com/v1/'

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [denial, setDenial] = useState(false);

  const onComplete = async (err, code, state) => {
    if (err) {
      setDenial(true);
      console.log(
        'An error occurred in the Connect flow, most likely because the user denied access'
      );
      return;
    }
    try {
      setIsLoading(true);
      const token = await axios
        .post(`${actualLambdaUrl}exchange/${code}`, { ...formData }, { headers: { 'Content-Type': "application/json" } });
      setSucess(true);
      setError(null);
      setIsLoading(false);
    } catch (error) {
      setError(new Error(error.response?.data?.error || 'Unknown error'));
      setIsLoading(false);
    }
  };
  console.log("redirect url: ",'https://javascript-sdk.smartcar.com/v2/redirect?app_origin=https://charginginsights.clearesult.com/')
//smartcar mode is live
  const smartcar = new Smartcar({
    clientId: '0461bae0-cf8c-42ea-8c81-5fe889f4675e',
    redirectUri: 'https://javascript-sdk.smartcar.com/v2/redirect?app_origin=https://charginginsights.clearesult.com/',
    scope: ['read_battery read_vehicle_info read_odometer read_charge read_location'],
    mode: 'live',
    onComplete,
  });

  const authorize = async (e) => {
    smartcar.openDialog({ forcePrompt: true });
  }

  return (
    <div className="container pb-5">
      <div className="row mt-4">
        <div className="col-sm-12 d-flex justify-content-center">
          <h1 className='heading'>
            <span>Smart Car Registration</span>
          </h1>
        </div>
        <div className='col-sm-12 d-flex justify-content-center mt-4'>
          {isLoading ? (<Loader />) : ((!!sucess || !!denial) ?
            (<div className='user-form'>
              <p className='success-text'>{!!sucess && "Congratulations! Your vehicle has been successfully registered. You can now enjoy smart car portal services."}</p>
              <p className='error-text'>{!!denial && "An error occurred in the Connect flow, most likely because the user denied access"}</p>
              <div className='d-flex justify-content-center mt-4'>
                <button onClick={() => { setShow(false); setSucess(false); setDenial(false); }} className='back-btn'>Go Back</button>
              </div>
            </div>) :
            (
              <>
                {!show && !sucess && !denial && <UserForm setShow={setShow} setFormData={setFormData} />}
                {!!show && !sucess && !denial && <Connect onClick={authorize} />}
              </>
            )
          )
          }
        </div>
      </div>
    </div>
  );
};
export default App;